// @flow
//Core
import React from 'react';
import classNames from 'classnames';
import { Container, Grid, Link as MUILink } from '@material-ui/core';
import { Link } from 'gatsby';
import formatDate from 'date-format';
import get from 'lodash.get';
//Local
import typography from '../../../../styles/typography.module.scss';
import { cardsSeStyles } from './styles';
import Card from '../../../Card/';
import { filterCardForPublish, filterCardsForPublish } from '../../utils';

const CardsSetSec = ({
  title,
  subtitle,
  primaryCard,
  secondaryCard,
  secondaryCardTitle,
  tertiaryCards,
  tertiaryCardsTitle,
  footnoteLink,
  centeredCardBackground,
}: {
  title: string,
  subtitle: string,
  primaryCard: Object,
  secondaryCard: Object,
  secondaryCardTitle: string,
  tertiaryCards: Object,
  tertiaryCardsTitle: string,
  footnoteLink?: Object,
  centeredCardBackground: boolean,
}) => {
  const styles = cardsSeStyles();
  const filteredPrimaryCard = filterCardForPublish(primaryCard);
  const filteredSecondaryCard = filterCardForPublish(secondaryCard);
  const filteredTertiaryCards = filterCardsForPublish(tertiaryCards);

  const smallCardsJSX =
    filteredTertiaryCards.length &&
    filteredTertiaryCards.map((card, index) => (
      <Card
        img={card.imgUrl}
        centeredBackground={centeredCardBackground}
        title={card.title}
        tags={card.tags}
        link={card.url}
        variant="primary"
        theme="light"
        size="sm"
        className={styles.smallCard}
        key={index}
        date={formatDate('yyyy/MM/dd', new Date(get(card, 'date')))}
      />
    ));

  return (
    <section className={styles.cardsSetSec}>
      <Container maxWidth="md">
        <h1
          className={classNames(
            typography.titleH3Dark,
            typography.withDividerPurple,
            styles.title
          )}
        >
          {title}
        </h1>
        <p
          className={classNames(
            typography.textXlDark,
            typography.alignCenter,
            styles.subtitle
          )}
        >
          {subtitle}
        </p>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6} md={7}>
            {filteredPrimaryCard && (
              <Card
                img={filteredPrimaryCard.imgUrl}
                centeredBackground={centeredCardBackground}
                title={filteredPrimaryCard.title}
                content={filteredPrimaryCard.previewContent}
                tags={filteredPrimaryCard.tags}
                variant="primary"
                theme="dark"
                size="lg"
                link={filteredPrimaryCard.url}
                className={styles.bigCard}
                date={formatDate(
                  'yyyy/MM/dd',
                  new Date(get(filteredPrimaryCard, 'date'))
                )}
              />
            )}
            <h2
              className={classNames(
                styles.columnTitle,
                typography.titleH4Dark,
                typography.withDividerLeftPurple
              )}
            >
              {secondaryCardTitle}
            </h2>
            {filteredSecondaryCard && (
              <Card
                img={filteredSecondaryCard.imgUrl}
                centeredBackground={centeredCardBackground}
                title={filteredSecondaryCard.title}
                tags={filteredSecondaryCard.tags}
                link={filteredSecondaryCard.url}
                variant="primary"
                theme="dark"
                size="md"
                className={styles.bigCard}
                date={formatDate(
                  'yyyy/MM/dd',
                  new Date(get(filteredSecondaryCard, 'date'))
                )}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <h2
              className={classNames(
                styles.columnTitle,
                styles.columnTitleTertiary,
                typography.titleH4Dark,
                typography.withDividerLeftPurple
              )}
            >
              {tertiaryCardsTitle}
            </h2>

            {smallCardsJSX}
          </Grid>
        </Grid>
        {footnoteLink && (
          <MUILink
            component={Link}
            to={footnoteLink.url}
            className={styles.footnoteLink}
          >
            {footnoteLink.title}
          </MUILink>
        )}
      </Container>
    </section>
  );
};

CardsSetSec.defaultProps = {
  hasBackground: false,
  footnoteLink: null,
  centeredCardBackground: true,
};

export default CardsSetSec;
