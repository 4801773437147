/* eslint-disable import/prefer-default-export */
import get from 'lodash.get';

export const filterCardsForPublish = (items) => {
  const now = +new Date();

  const normalizeItems = items.map((item) =>
    get(item, 'fields')
      ? { ...get(item, 'fields'), ...{ id: get(item, 'sys.id') } }
      : item
  );

  return normalizeItems.filter((n) => new Date(n.date) <= now);
};

export const filterCardForPublish = (item) => {
  const now = +new Date();

  return new Date(item.date) <= now ? item : null;
};

export const filterRecommendedCardsForPublish = (items) => {
  const now = +new Date();

  return items.filter((n) => new Date(n.node.date) <= now).slice(0, 6);
};
