/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const cardsSeStyles = makeStyles(() => ({
  cardsSetSec: {
    padding: '100px 0',
    '@media (max-width: 1024px)': {
      padding: '85px 0 50px 0',
    },
  },
  title: {
    marginBottom: '30px!important',
    '@media (max-width: 1024px)': {
      marginTop: '18px',
      marginBottom: '23px',
      fontSize: '28px',
    },
  },
  subtitle: {
    marginBottom: '72px',
    '@media (max-width: 1024px)': {
      marginBottom: '50px',
      fontSize: '18px',
    },
  },
  columnTitle: {
    marginBottom: '55px',
  },
  columnTitleTertiary: {
    marginTop: '-6px',
  },
  smallCard: {
    marginBottom: '30px',
    '@media (max-width: 1024px)': {
      marginBottom: '24px',
    },
  },
  bigCard: {
    marginBottom: '50px',
    '@media (max-width: 1024px)': {
      marginBottom: '45px',
    },
  },
  footnoteLink: {
    textDecoration: 'underline',
    '@media (max-width: 1024px)': {
      marginTop: 30,
      display: 'inline-block',
    },
  },
}));
