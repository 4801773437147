//Core
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';
//Local
import { Layout } from '../../../components';
import {
  CardsSetSec,
  CardArticlesListSec,
} from '../../../components/mediaCenterSections/blogPreview';
import * as MediaCenterActions from '../../../redux/mediaCenter/actions';

const MediaCenterTop = () => {
  const topPage = useSelector((state) => state.mediaCenter.workLifeTopPage);
  const dispatch = useDispatch();

  // Did mount
  useEffect(() => {
    dispatch(MediaCenterActions.getWorkLifeTopPageAsync());
  }, [dispatch]);

  return (
    <Layout isMediaCenter>
      <CardsSetSec
        title={get(topPage, 'pageTitle')}
        subtitle={get(topPage, 'pageSubtitle')}
        primaryCard={{
          ...get(topPage, 'firstSectionPrimaryArticle.fields', {}),
          imgUrl: get(
            topPage,
            'firstSectionPrimaryArticle.fields.previewImage.fields.file.url'
          ),
          url: `/media_center/${get(
            topPage,
            'firstSectionPrimaryArticle.fields.blogSection.fields.urlKey'
          )}/article/${get(topPage, 'firstSectionPrimaryArticle.sys.id')}`,
          tags: get(topPage, 'firstSectionPrimaryArticle.fields.tags', []).map(
            (item) => item.fields.title
          ),
        }}
        secondaryCard={{
          ...get(topPage, 'firstSectionSecondaryArticle.fields', {}),
          imgUrl: get(
            topPage,
            'firstSectionSecondaryArticle.fields.previewImage.fields.file.url'
          ),
          url: `/media_center/${get(
            topPage,
            'firstSectionSecondaryArticle.fields.blogSection.fields.urlKey'
          )}/article/${get(topPage, 'firstSectionSecondaryArticle.sys.id')}`,
          tags: get(
            topPage,
            'firstSectionSecondaryArticle.fields.tags',
            []
          ).map((item) => item.fields.title),
        }}
        secondaryCardTitle={get(topPage, 'firstSectionSecondaryArticleTitle')}
        tertiaryCards={get(topPage, 'firstSectionTertiaryArticles', []).map(
          (item) => ({
            ...item.fields,
            imgUrl: get(item, 'fields.previewImage.fields.file.url'),
            url: `/media_center/${get(
              item,
              'fields.blogSection.fields.urlKey'
            )}/article/${get(item, 'sys.id')}`,
            tags: get(item, 'fields.tags', []).map(
              (category) => category.fields.title
            ),
          })
        )}
        tertiaryCardsTitle={get(topPage, 'firstSectionTertiaryArticlesTitle')}
        footnoteLink={{
          title: 'HRブログの全記事はこちら',
          url: '/media_center/work_life/hr/',
        }}
      />

      <CardArticlesListSec
        title={get(topPage, 'secondSectionTitle')}
        card={{
          ...get(topPage, 'secondSectionPrimaryArticle.fields', {}),
          imgUrl: get(
            topPage,
            'secondSectionPrimaryArticle.fields.previewImage.fields.file.url'
          ),
          url: `/media_center/${get(
            topPage,
            'secondSectionPrimaryArticle.fields.blogSection.fields.urlKey'
          )}/article/${get(topPage, 'secondSectionPrimaryArticle.sys.id')}`,
          tags: get(topPage, 'secondSectionPrimaryArticle.fields.tags', []).map(
            (item) => item.fields.title
          ),
        }}
        articles={get(topPage, 'secondSectionSecondaryArticles', []).map(
          (item) => ({
            ...item.fields,
            url: `/media_center/${get(
              item,
              'fields.blogSection.fields.urlKey'
            )}/article/${get(item, 'sys.id')}`,
            tags: get(item, 'fields.tags', []).map(
              (category) => category.fields.title
            ),
          })
        )}
        footnoteLink={{
          title: 'テックブログの全記事はこちら',
          url: '/media_center/work_life/engineering/',
        }}
        hasBackground
      />

      <CardArticlesListSec
        title={get(topPage, 'thirdSectionTitle')}
        card={{
          ...get(topPage, 'thirdSectionPrimaryArticle.fields', {}),
          imgUrl: get(
            topPage,
            'thirdSectionPrimaryArticle.fields.previewImage.fields.file.url'
          ),
          url: `/media_center/${get(
            topPage,
            'thirdSectionPrimaryArticle.fields.blogSection.fields.urlKey'
          )}/article/${get(topPage, 'thirdSectionPrimaryArticle.sys.id')}`,
          tags: get(topPage, 'thirdSectionPrimaryArticle.fields.tags', []).map(
            (item) => item.fields.title
          ),
        }}
        articles={get(topPage, 'thirdSectionSecondaryArticles', []).map(
          (item) => ({
            ...item.fields,
            url: `/media_center/${get(
              item,
              'fields.blogSection.fields.urlKey'
            )}/article/${get(item, 'sys.id')}`,
            tags: get(item, 'fields.tags', []).map(
              (category) => category.fields.title
            ),
          })
        )}
        footnoteLink={{
          title: '採用情報の全記事はこちら',
          url: '/media_center/work_life/recruitment',
        }}
      />
    </Layout>
  );
};

export default MediaCenterTop;
